import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Title, Text, Block, Button, Vector } from '@superlogica/design-ui'

import * as S from './View.styled'

const SectionIntro = () => (
  <S.SectionIntro>
    <Container>
      <Row>
        <Col md={5}>
          <Block
            tag="header"
            maxWidth={{ xxxs: '100%', md: 420 }}
            marginBottom={{ xxxs: 40, md: 0 }}
          >
            <Title
              tag="h1"
              font="heading"
              size="display01"
              color="babar900"
              secondColor="sonic500"
              marginBottom={24}
            >
              Cultura <span>Superlógica</span>
            </Title>
            <Text color="babar900" size="body03">
              <strong>
                Somos protagonistas, ousados, e entendemos que fazemos tudo
                JUNTOS.
              </strong>{' '}
              Isso é fundamental para a evolução que queremos no mundo.
            </Text>
          </Block>
        </Col>
        <Col md={7}>
          <Block tag="article" bgColor="trooper50">
            <S.Content>
              <Title
                color="babar900"
                size="overline02"
                letterSpacing={1}
                marginBottom={16}
                weight="bold"
                uppercase
              >
                A Cultura é o nosso DNA
              </Title>
              <Text marginBottom={32}>
                Vivemos uma cultura ágil, dinâmica e colaborativa, é ela que nos
                faz sermos únicos e nutre o ambiente e relações de confiança.
                Isso envolve aprender novos comportamentos que são úteis para
                formar nossa mentalidade e atitudes.
              </Text>
              <Button
                tag="a"
                href="https://www.inhire.com.br/carreiras-superlogica/"
                title="Vem ser super com a gente"
                target="_blank"
                uppercase
                weight="bold"
                appearance="link"
                className="arrow-animation arrow-right-animation"
                rightIcon={<Vector name="arrow-right-animation" />}
                iconSize={12}
              >
                Vem ser super com a gente
              </Button>
            </S.Content>
          </Block>
        </Col>
      </Row>
    </Container>
  </S.SectionIntro>
)

export default SectionIntro
