import React from 'react'
import SectionBooks from './Section.Books'

import SectionIntro from './Section.intro'
import SectionMosaic from './Section.Mosaic'
import SectionValues from './Section.Values'

const CulturaView = () => (
  <>
    <SectionIntro />
    <SectionMosaic />
    <SectionValues />
    <SectionBooks />
  </>
)

export default CulturaView
