import React, { useRef } from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Title, Text, Block, Carousel, Vector } from '@superlogica/design-ui'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { BookCard } from 'components/app'

import * as S from './View.styled'

interface BooksDataProps {
  allBooksJson: {
    nodes: {
      id: number
      title: string
      author: string
      cover: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
  }
}

const SectionBooks = () => {
  const swiperRef = useRef<any>()

  const {
    allBooksJson: { nodes: books }
  } = useStaticQuery<BooksDataProps>(
    graphql`
      query {
        allBooksJson(filter: { area: { eq: "cultura" } }) {
          nodes {
            id
            title
            author
            cover {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 90
                  height: 260
                )
              }
            }
          }
        }
      }
    `
  )

  return (
    <S.SectionBooks>
      <Container>
        <Block tag="header" marginBottom={{ xxxs: 24, md: 40 }}>
          <Title
            font="heading"
            size="display03"
            color="babar900"
            marginBottom={16}
          >
            Livros e cultura
          </Title>
          <Text size="body03" color="babar900">
            <strong>
              É o conhecimento que nos auxilia para formação segura da
              mentalidade que construímos e buscamos.
            </strong>
            <br />A teoria vai ajudar a trabalhar em velocidade, pensar em
            hipóteses, quebrar crenças limitantes e assim alçar vôos mais altos.
          </Text>
        </Block>
      </Container>
      <Container>
        <Block tag="article" paddingBottom={{ xxxs: 24, md: 40 }}>
          <Carousel
            fullMobile
            swiperRef={swiperRef}
            swiper={{
              initialSlide: 0,
              observer: true,
              spaceBetween: 32,
              watchOverflow: true,
              simulateTouch: false,
              centeredSlides: false,
              centerInsufficientSlides: false,
              pagination: false,
              slidesPerView: 4,
              breakpoints: {
                0: {
                  slidesPerView: 1
                },
                526: {
                  slidesPerView: 2
                },
                996: {
                  slidesPerView: 3
                }
              }
            }}
          >
            {books.map(({ id, title, author, cover }) => (
              <BookCard
                key={id}
                title={title}
                author={author}
                cover={
                  <GatsbyImage
                    image={cover.childImageSharp.gatsbyImageData}
                    alt={author}
                    objectFit="cover"
                  />
                }
              />
            ))}
          </Carousel>
        </Block>
      </Container>
      <Block tag="footer" bgColor="trooper75">
        <Container>
          <S.InnerWrapper>
            <S.ButtonPrev onClick={() => swiperRef.current.swiper.slidePrev()}>
              <Vector name="arrow-left" width={20} height={20} />
            </S.ButtonPrev>
            <S.ButtonNext onClick={() => swiperRef.current.swiper.slideNext()}>
              <Vector name="arrow-right" width={20} height={20} />
            </S.ButtonNext>
          </S.InnerWrapper>
        </Container>
      </Block>
    </S.SectionBooks>
  )
}

export default SectionBooks
