import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'

import * as S from './View.styled'

interface MosaicCultureProps {
  allPhotosJson: {
    nodes: {
      order: number
      alt: string
      image: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
      category: string
    }[]
  }
}

const SectionMosaic = () => {

  const {
    allPhotosJson: { nodes: photos }
  } = useStaticQuery<MosaicCultureProps>(
    graphql`
      query {
        allPhotosJson(filter: {category: {eq: "mosaic-culture"}}) {
          nodes {
            alt
            category
            order
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 85)
              }
            }
          }
        }
      }
    `
  )

  return (
  <S.SectionMosaic>
    {photos.map((item) => (
      <S.MosaicItem key={item.order}>
        <GatsbyImage
          alt={item.alt}
          image={item.image.childImageSharp.gatsbyImageData}
          loading="eager"
          objectFit="cover"
          objectPosition="top"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </S.MosaicItem>
    ))}
  </S.SectionMosaic>
)}

export default SectionMosaic
