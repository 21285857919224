import * as React from 'react'

import { DefaultLayout } from 'layouts'
import CulturaView from 'views/cultura'
import { Seo } from 'components/helpers'

const IndexPage: React.FC = () => (
  <DefaultLayout>
    <Seo
      title="Cultura"
      description="Somos protagonistas, ousados, e entendemos que fazemos tudo JUNTOS. Isso é fundamental para a evolução que queremos no mundo."
    />
    <CulturaView />
  </DefaultLayout>
)

export default IndexPage
