import styled, { css } from 'styled-components'
import { parseColor, media } from '@superlogica/design-ui'
import { rem } from 'polished'
import { motion } from 'framer-motion'

export const ButtonPrev = styled.button``

export const ButtonNext = styled.button``

export const Content = styled.div``

export const InnerWrapper = styled.div``

export const MosaicItem = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  whileInView: 'visible',
  viewport: { once: true },
  transition: { duration: 0.5 },
  variants: {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 }
  }
}))`
  width: 100%;
  object-fit: cover;

  &:nth-child(1),
  &:nth-child(5) {
    grid-column: span 4;
    grid-row: span 6;
  }
  &:nth-child(2) {
    grid-column: span 8;
    grid-row: span 6;
  }
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8),
  &:nth-child(9),
  &:nth-child(10) {
    grid-column: span 4;
    grid-row: span 3;
  }

  ${media.lessThan('xxs')} {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      grid-column: span 4;
      grid-row: span 6;
    }
    &:nth-child(6),
    &:nth-child(7) {
      grid-column: span 6;
      grid-row: span 5;
    }
    &:nth-child(8) {
      grid-column: span 6;
      grid-row: span 14;
    }
    &:nth-child(9),
    &:nth-child(10) {
      grid-column: span 6;
      grid-row: span 7;
    }
  }
`

export const SectionIntro = styled.section`
  padding-top: ${rem(140)};
  overflow: hidden;

  ${Content} {
    position: relative;
    border-top-right-radius: ${rem(40)};
    padding: ${rem(68)};
    border-bottom: 80px solid;

    &:after {
      content: '';
      position: absolute;
      width: 100vw;
      height: calc(100% + 80px);
      top: 0;
      right: -100vw;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper25)};

    ${Content} {
      background-color: ${parseColor(colors.babar0)};
      border-bottom-color: ${parseColor(colors.sonic500)};

      &:after {
        background: linear-gradient(
          to top,
          ${parseColor(colors.sonic600)} 80px,
          ${parseColor(colors.trooper50)} 0
        );
      }

      ${media.lessThan('md')} {
        border-bottom: 40px solid;
        border-bottom-color: ${parseColor(colors.sonic500)};
        &:after {
          background: linear-gradient(
            to top,
            ${parseColor(colors.sonic600)} 40px,
            ${parseColor(colors.trooper50)} 0
          );
        }
      }
    }
  `}

  ${media.lessThan('md')} {
    padding-top: ${rem(100)};

    ${Content} {
      padding: ${rem(40)} ${rem(24)};

      &:after {
        height: calc(100% + 40px);
      }
    }
  }
`

export const SectionMosaic = styled.section`
  overflow: hidden;
  width: 100%;
  max-height: 650px;

  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-gap: ${rem(4)};

  ${media.lessThan('xxs')} {
    max-height: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(24, 1fr);
  }
`

export const SectionValues = styled.section`
  padding: ${rem(80)} 0 ${rem(100)};

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.sonic500)};
  `}

  ${media.lessThan('md')} {
    padding: ${rem(60)} 0;
  }
`

export const SectionBooks = styled.section`
  width: 100%;
  overflow: hidden;
  padding-top: ${rem(80)};

  .swiper {
    overflow: initial;
  }

  ${InnerWrapper} {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    justify-content: flex-end;

    &:after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      top: 0;
      right: -100vw;
    }
  }

  ${ButtonPrev},
  ${ButtonNext} {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 0;
    outline: 0;
    width: 80px;
    height: 80px;
    position: relative;
  }

  ${ButtonPrev} {
    &:before {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 50%;
      top: 20px;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper0)};

    ${InnerWrapper} {
      background-color: ${parseColor(colors.trooper75)};
      &:after {
        background-color: ${parseColor(colors.trooper50)};
      }
    }
    ${ButtonPrev} {
      &:before {
        background-color: ${parseColor(colors.trooper100)};
      }
    }

    ${ButtonPrev},
    ${ButtonNext} {
      svg {
        color: ${parseColor(colors.babar900)};
      }

      background-color: ${parseColor(colors.trooper50)};

      &:hover {
        svg {
          color: ${parseColor(colors.sonic500)};
        }
      }
    }
  `}

  ${media.lessThan('md')} {
    padding-top: ${rem(60)};

    ${ButtonPrev},
    ${ButtonNext} {
      width: 60px;
      height: 60px;
    }
    ${ButtonPrev} {
      &:before {
        top: 15px;
      }
    }
  }
`
