import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Title, Block } from '@superlogica/design-ui'
import { FeatureCard } from 'components/app'
import { graphql, useStaticQuery } from 'gatsby'
import { VectorsProps } from 'svg/vectors'

import * as S from './View.styled'

interface ValuesDataProps {
  allValuesJson: {
    nodes: {
      id: number
      title: string
      body: string
      icon: VectorsProps
    }[]
  }
}

const SectionValues = () => {
  const {
    allValuesJson: { nodes: values }
  } = useStaticQuery<ValuesDataProps>(
    graphql`
      query {
        allValuesJson(filter: { area: { eq: "cultura" } }) {
          nodes {
            id
            title
            body
            icon
          }
        }
      }
    `
  )

  return (
    <S.SectionValues>
      <Container>
        <Title font="heading" size="display03" color="babar0" marginBottom={40}>
          Nossos valores
        </Title>
        <Block
          tag="article"
          display="grid"
          gap={48}
          gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr' }}
        >
          {values.map(({ id, title, body, icon }) => (
            <FeatureCard
              key={id}
              color="babar0"
              title={title}
              titleColor="babar0"
              bodyColor="babar0"
              body={body}
              vector={icon}
              orientation="horizontal"
              bgVector="babar0"
            />
          ))}
        </Block>
      </Container>
    </S.SectionValues>
  )
}

export default SectionValues
